import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom/dist'
import { api, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets, getTicketTypesByEventId } from 'ticketino-api-client'
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Participation = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [eventId] = useState(sessionStorage.getItem("eventId"));
    const [selectedCategory] = useState(sessionStorage.getItem("selectedCategory"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [agb, setAgb] = useState({
        accept: false,
        publication: true
    });

    const [organizerId, setOrganizerId] = useState();

    const [ticketTypesAvailability, setTicketTypesAvailability] = useState([]);

    const [ticketTypesAdded, setTicketTypesAdded] = useState([]);
    const [tagungsBandsAdded, setTagungsBandsAdded] = useState(0);

    const [ticketAddress, setTicketAddress] = useState([]);
    const [ticketsToAdd, setTicketsToAdd] = useState([]);

    const [tagungsBands, setTagungsBands] = useState();
    const [addTagungsband, setAddTagungsband] = useState(false);

    const [promotioncodeUnlock, setPromotioncodeUnlock] = useState("");
    const [promotioncodeDiscount, setPromotioncodeDiscount] = useState("");
    const [isPromotionAddedUnlock, setIsPromotionAddedUnlock] = useState(false);
    const [addedPromotion, setAddedPromotion] = useState("");

    const [isReductionPromotionAdded, setIsReductionPromotionAdded] = useState(false);
    const [addedReductionPromotion, setAddedReductionPromotion] = useState("");

    const [promotionErrorUnlock, setPromotionErrorUnlock] = useState("");
    const [promotionErrorDiscount, setPromotionErrorDiscount] = useState("");

    // workshop ticketTypeIds
    const [settings, setSettings] = useState({
        stsTicketTypeId: 0,
        congressTicketTypeId: 0,
        kolloquiumTicketTypeId: 0,
        tagungsbandTicketTypeId: 0,
        guestAndSponsorTicketTypeWithVisitId: 0,
        guestAndSponsorTicketTypeWithoutVisitId: 0,
        isGuestAndSponsorWithVisitAvailable: true
    })

    const [submissionError, setSubmissionError] = useState("");

    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]);

    const requestFormSettings = async () => {
        try {
            const response = await axios.get(`form/formsettings`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async () => {
        try {
            // request form settings
            const updatedSettings = await requestFormSettings();

            setOrganizerId(updatedSettings.organizerId);

            const updatedOrder = await getOrderByOrderId(orderId);

            // TicketType
            let ticketTypes = await getTicketTypesByEventId(eventId);

            let updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeName = info[0].name;

                    // only return ticketTypes which belong the the selected Category
                    if (ticketTypeName.startsWith(selectedCategory)) {
                        return { ...ticketType, info: ticketTypeInfo }
                    }
                })
            )

            updatedTicketTypes = updatedTicketTypes.filter((utt) => utt?.id > 0);

            let congressTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.congressTicketTypeIds.includes(utt.id))?.id;
            let tagungsbandTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.tagungsbandTicketTypeIds.includes(utt.id))?.id;
            let guestSponserWithVisitTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.guestAndSponsorTicketTypeWithVisitIds.includes(utt.id))?.id;
            let guestSponserWithoutVisitTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.guestAndSponsorTicketTypeWithoutVisitIds.includes(utt.id))?.id;

            let ticketTypeIdsToAdd = updatedOrder.tickets.map((ticket) => { return ticket.ticketTypeId });
            let ticketAddressToAdd = updatedOrder.tickets.map((ticket) => { return { ticketTypeId: ticket.ticketTypeId, option1: ticket.option1 } });

            let congressTicket = ticketAddressToAdd.find(a => a.ticketTypeId == congressTicketTypeId && a.option1?.length > 0);

            if (congressTicket) {
                ticketAddressToAdd.find(a => a.ticketTypeId == congressTicketTypeId).option1 = "";
            }

            // filtering out the tagunsband ticketTypes
            ticketTypeIdsToAdd = ticketTypeIdsToAdd.filter(id => id !== tagungsbandTicketTypeId);
            ticketAddressToAdd = ticketAddressToAdd.filter(address => address.ticketTypeId !== tagungsbandTicketTypeId);

            let tagungsbands = 0;

            updatedOrder.tickets.map((ticket) => {
                if (ticket.ticketTypeId == tagungsbandTicketTypeId) {
                    tagungsbands++;
                }
            })

            let ticketTypeIds = updatedTicketTypes.map(utt => utt.id);
            ticketTypeIds.push(tagungsbandTicketTypeId, guestSponserWithVisitTicketTypeId, guestSponserWithoutVisitTicketTypeId)


            let updatedTicketTypesAvailability = await Promise.all(ticketTypeIds.map(async (id) => {
                const availability = await getTicketTypeNumberOfAvailableTickets(id, orderId);
                return { ticketTypeId: id, availability: { ...availability } }
            }))

            let maxTicketsGuestAndSponsorWithVisit = updatedSettings.maxTicketsGuestAndSponsorWithVisit;
            let totalTicketsSoldGuestAndSponsorWithVisit = 0;

            await Promise.all(
                updatedSettings.guestAndSponsorTicketTypeWithVisitIds.map(async (ticketTypeId) => {
                    var ticketsSold = await getNumberOfTicketsSoldOfTicketType(ticketTypeId) ?? 0;
                    totalTicketsSoldGuestAndSponsorWithVisit = totalTicketsSoldGuestAndSponsorWithVisit + ticketsSold;
                })
            )

            // Remove unlocked tickets (guestAndSponsorTicketType) from order so that they work on backwards navigation
            if (updatedOrder && updatedOrder.tickets && updatedOrder.tickets.length > 0) {
                let ticketIdsToDelete = updatedOrder.tickets
                    .map(ticket =>
                        (ticket.ticketTypeId === guestSponserWithoutVisitTicketTypeId ||
                            ticket.ticketTypeId === guestSponserWithVisitTicketTypeId) ? ticket.id : null
                    )
                    .filter(id => id !== null);

                if (ticketIdsToDelete.length > 0) {
                    await deleteTicketsFromBasket(ticketIdsToDelete);
                }

                // Remove guestAndSponsorTicketType IDs from ticketTypeIdsToAdd
                ticketTypeIdsToAdd = ticketTypeIdsToAdd.filter(
                    id => id !== guestSponserWithoutVisitTicketTypeId &&
                        id !== guestSponserWithVisitTicketTypeId
                );
            }

            setSettings({
                stsTicketTypeId: updatedSettings.stsTicketTypeId,
                congressTicketTypeId: congressTicketTypeId,
                tagungsbandTicketTypeId: tagungsbandTicketTypeId,
                guestAndSponsorTicketTypeWithVisitId: guestSponserWithVisitTicketTypeId,
                guestAndSponsorTicketTypeWithoutVisitId: guestSponserWithoutVisitTicketTypeId,
                isGuestAndSponsorWithVisitAvailable: !(totalTicketsSoldGuestAndSponsorWithVisit >= maxTicketsGuestAndSponsorWithVisit),
            });

            setTicketTypesAdded(ticketTypeIdsToAdd);
            setTicketsToAdd(ticketTypeIdsToAdd);
            setTicketAddress(ticketAddressToAdd);

            setTagungsBands(tagungsbands == 0 ? null : tagungsbands);
            setTagungsBandsAdded(tagungsbands);

            if (tagungsbands > 0) {
                setAddTagungsband(true);
            }

            setTicketTypesAvailability(updatedTicketTypesAvailability);
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCodeToOrder = async (orderId, code, isUnlockPromocode) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            if (isUnlockPromocode) {
                setPromotionErrorUnlock(resources?.ErrorPromotionCode);
            } else {
                setPromotionErrorDiscount(resources?.ErrorPromotionCode);
            }
        }
    }

    const getNumberOfTicketsSoldOfTicketType = async (ticketTypeId) => {
        try {
            const res = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/TicketsSold`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const addPromotioncodeUnlock = async () => {
        let response;

        try {
            response = await axios.get(`${baseUrl}/PromotionCode/${promotioncodeUnlock}/Promotion?organizerId=${organizerId}`);
        } catch (error) {
            setPromotionErrorUnlock(resources?.ErrorPromotionCode);
        }

        if (response.data.unlockTicketTypeGroup.length > 0) {
            const order = await applyPromotionCodeToOrder(orderId, promotioncodeUnlock, true);

            if (order?.unlockedTicketTypes?.includes(settings.guestAndSponsorTicketTypeWithVisitId) ||
                order?.unlockedTicketTypes?.includes(settings.guestAndSponsorTicketTypeWithoutVisitId)) {
                /*onGuestCheckboxChange(workshopTicketMapping["GuestAndSponsorWithoutVisit"], workshopTicketMapping["GuestAndSponsorWithVisit"]);*/
                setIsPromotionAddedUnlock(true);
                setPromotionErrorUnlock("");
                setPromotionErrorDiscount("");
                setAddedPromotion(promotioncodeUnlock);
            }
        } else {
            setPromotionErrorUnlock(resources?.ErrorPromotionCode);
        }
    }

    const addPromotioncodeDiscount = async () => {
        let response;

        try {
            response = await axios.get(`${baseUrl}/PromotionCode/${promotioncodeDiscount}/Promotion?organizerId=${organizerId}`);
        } catch (error) {
            setPromotionErrorDiscount(resources?.ErrorPromotionCode);
        }

        if (response.data.unlockTicketTypeGroup.length === 0) {
            const order = await applyPromotionCodeToOrder(orderId, promotioncodeDiscount, false);

            if (order.id > 0) {
                setIsReductionPromotionAdded(true);
                setPromotionErrorUnlock("");
                setPromotionErrorDiscount("");
                setAddedReductionPromotion(promotioncodeDiscount);
                await removePromotionCode(promotioncodeDiscount);
            }
        } else {
            setPromotionErrorDiscount(resources?.ErrorPromotionCode);
        }
    }

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const addTicketsToBasket = async (addTicketTypes) => {
        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { ticketsToAdd: addTicketTypes });
            return response.data;
        } catch (error) {
            setSubmissionError(error.response.data);
            console.error(error);
        }
    }

    const deleteTicketsFromBasket = async (ticketIds) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: { "TicketsToRemove": ticketIds } });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const updateAddressOfTicket = async (ticketId, body) => {
        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, body);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const workshopTicketMapping = {
        "Kolloquium": settings.kolloquiumTicketTypeId,
        "Congress": settings.congressTicketTypeId,
        "Tagungsband": settings.tagungsbandTicketTypeId,
        "GuestAndSponsor": settings.guestAndSponsorTicketTypeWithVisitId,
        "GuestAndSponsorWithoutVisit": settings.guestAndSponsorTicketTypeWithoutVisitId,
        "GuestAndSponsorWithVisit": settings.guestAndSponsorTicketTypeWithVisitId
    };

    const onCheckboxChange = (workshop) => {
        const ticketTypeId = workshopTicketMapping[workshop];

        // hard coding settings for congrss ticket type
        if (ticketTypeId == settings.congressTicketTypeId) {
            if (!ticketsToAdd.includes(settings.congressTicketTypeId)) {
                setTicketAddress((address) => [...address, { ticketTypeId: ticketTypeId, option1: resources?.Workshop4Sub1 }]);
            } else {
                setTicketAddress((address) => {

                    if (address?.findIndex(a => a.ticketTypeId == ticketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== ticketTypeId);
                    }

                    return address;
                })
            }
        }

        if (ticketTypeId) {
            setTicketsToAdd((tickets) => {
                if (tickets.includes(ticketTypeId)) {
                    return tickets.filter((t) => t !== ticketTypeId);
                } else {
                    return [...tickets, ticketTypeId];
                }
            });
        }
    }

    const onGuestCheckboxChange = (ticketTypeToAdd, ticketTypeToRemove) => {
        if (ticketTypeToAdd) {
            setTicketsToAdd((tickets) => {
                const updatedTickets = tickets.filter((t) => t !== ticketTypeToRemove);

                if (!tickets.includes(ticketTypeToAdd)) {
                    return [...updatedTickets, ticketTypeToAdd];
                }

                return updatedTickets;
            });
        }
    };

    const onTicketAddressChange = (workshop, value, parentTicketTypeId) => {
        const ticketTypeId = workshopTicketMapping[workshop];

        // hard coding settings for congrss ticket type
        if (parentTicketTypeId) {

            if (!ticketsToAdd.includes(parentTicketTypeId)) {
                setTicketsToAdd([...ticketsToAdd, parentTicketTypeId])
            }

            setTicketAddress((address) => {
                if (address?.findIndex(a => a.ticketTypeId == settings.congressTicketTypeId) > -1) {
                    return address?.filter((a) => a?.ticketTypeId !== settings.congressTicketTypeId);
                }

                return address;
            })
        }

        if (ticketTypeId) {
            setTicketAddress((address) => {
                const addressIndex = address.findIndex((a) => a.ticketTypeId === ticketTypeId);

                if (addressIndex !== -1) {
                    const updatedAddress = [...address];
                    updatedAddress[addressIndex] = { ...address[addressIndex], option1: value };
                    return updatedAddress;
                } else {
                    return [...address, { ticketTypeId, option1: value }];
                }
            });
        }

        if (!(ticketsToAdd.includes(ticketTypeId)) && !(parentTicketTypeId == ticketTypeId)) {
            setTicketsToAdd((tickets) => {
                return [...tickets, ticketTypeId];
            });
        }
    };

    const onSubmit = async () => {
        const validation = validate();

        const sortOrder = [
            settings.kolloquiumTicketTypeId,
            settings.guestAndSponsorTicketTypeWithVisitId,
            settings.guestAndSponsorTicketTypeWithoutVisitId,
            settings.congressTicketTypeId,
            settings.tagungsbandTicketTypeId
        ];

        if (!validation) {
            return;
        }

        let addTicketTypes = [];
        let addTagungsbandTicketTypes = [];
        let deleteTagungsbandTicketIdsToDelete = [];

        addTicketTypes = ticketsToAdd.map((ticketTypeId) => {
            return { ticketTypeId: ticketTypeId, quantity: 1 }
        })

        addTicketTypes = addTicketTypes.sort((a, b) => {
            const indexA = sortOrder.indexOf(a.ticketTypeId);
            const indexB = sortOrder.indexOf(b.ticketTypeId);

            return indexA - indexB
        })

        if (isPromotionAddedUnlock && (ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithVisitId))) {
            addTicketTypes.find(att => att.ticketTypeId == settings.guestAndSponsorTicketTypeWithVisitId).promotionCode = addedPromotion;
        }

        if (isPromotionAddedUnlock && (ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithoutVisitId))) {
            addTicketTypes.find(att => att.ticketTypeId == settings.guestAndSponsorTicketTypeWithoutVisitId).promotionCode = addedPromotion;
        }

        // prevent adding ticketTypes which have already been added
        addTicketTypes = addTicketTypes.filter((att) => !ticketTypesAdded.includes(att.ticketTypeId));

        // remove ticketTypes which have been changed
        let deleteTicketTypes = ticketTypesAdded.filter((tta) => !ticketsToAdd.includes(tta));


        const order = await addTicketsToBasket(addTicketTypes);

        await Promise.all(order.tickets.map(async (ticket) => {
            let address = ticketAddress.find((ta) => ta.ticketTypeId == ticket.ticketTypeId);

            if (address?.option1) {
                await updateAddressOfTicket(ticket.id, { option1: address.option1 });
            }
        }))

        if (agb.publication) {
            sessionStorage.setItem("agbPublication", true);
        }

        if (deleteTicketTypes.length > 0) {
            let ticketIdsToDelete = order.tickets.map(ticket => {
                if (deleteTicketTypes.includes(ticket.ticketTypeId)) {
                    return ticket.id
                }
            }).filter(t => t > 0)

            await deleteTicketsFromBasket(ticketIdsToDelete);
        }

        // configuring tagungausband
        let amountTagungsBands = parseInt(tagungsBands ?? 0) - parseInt(tagungsBandsAdded ?? 0);

        if (amountTagungsBands > 0) {
            addTagungsbandTicketTypes.push({ ticketTypeId: settings.tagungsbandTicketTypeId, quantity: amountTagungsBands })
        }

        if (addTagungsbandTicketTypes.length > 0) {
            await addTicketsToBasket(addTagungsbandTicketTypes);
        }

        order.tickets.forEach((ticket) => {
            if (ticket.ticketTypeId == settings.tagungsbandTicketTypeId) {
                if (amountTagungsBands < 0) {
                    deleteTagungsbandTicketIdsToDelete.push(ticket.id)
                    amountTagungsBands++;
                }
            }
        })

        if (deleteTagungsbandTicketIdsToDelete.length > 0) {
            await deleteTicketsFromBasket(deleteTagungsbandTicketIdsToDelete);
        }

        const updatedOrder = await getOrderByOrderId(orderId);


        if (isReductionPromotionAdded && addedReductionPromotion != "") {
            await applyPromotionCodeToOrder(orderId, addedReductionPromotion);
            sessionStorage.setItem("addedPromotion", addedReductionPromotion);
        }

        navigate(`/${language}/checkout`);
    }

    const validateSubmission = () => {

        let validation = true;

        if (agb.accept != true) {
            validation = false;
        }

        if (ticketsToAdd.length <= 0 && (tagungsBands <= 0 || !tagungsBands)) {
            validation = false;
        }

        if (addTagungsband && !(tagungsBands > 0)) {
            validation = false;
        }

        if (isPromotionAddedUnlock && (!ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithVisitId) &&
            !ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithoutVisitId))) {
            validation = false;
        }

        return validation;
    }

    const validate = () => {

        let tagungsbandAvailability = ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.tagungsbandTicketTypeId)?.availability?.availableTickets;

        if (tagungsBands > tagungsbandAvailability) {
            setSubmissionError(`Leider stehen nicht ausreichend Tagungsbände zur Verfügung. Bitte wählen Sie eine geringere Anzahl. Derzeit sind nur ${tagungsbandAvailability} Tagungsbände verfügbar.`);
            return false;
        }

        return true;
    }

    let validation = validateSubmission();
    let disabled = !validation;

    const openAGB = () => {
        window.open(`/${language}/agb`, '_blank');
    };

    return (
        <div className='container page-container'>
            <Header />
            <div className='mt-3'>
                <div className='text-center'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources?.ParticipationTitle}</span>
                    </p>
                </div>

                {/* promo ticket guest-sponser */}
                {(isPromotionAddedUnlock || ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithVisitId) || ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithoutVisitId)) && <div className={`category row promotion-category`}>

                    <div className="col-2 fs-5 text-secondary"><i className={`bi bi-check-square`}></i></div>
                    <div className="col-10">{resources?.Workshop3}</div>

                    <div className="col-12">
                        <div className='row mt-2'>
                            <div className="offset-2 col-2 fs-5 text-secondary" onClick={() => onGuestCheckboxChange(workshopTicketMapping["GuestAndSponsorWithoutVisit"], workshopTicketMapping["GuestAndSponsorWithVisit"])}><i className={`bi bi${ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithoutVisitId) ? "-check" : ""}-circle`}></i></div>
                            <div className="col-8">
                                {resources?.Workshop3Sub1}
                            </div>
                        </div>
                        <div className={"row mt-2 " + (!settings.isGuestAndSponsorWithVisitAvailable ? "category-hide" : "")}>
                            <div className="offset-2 col-2 fs-5 text-secondary" onClick={() => onGuestCheckboxChange(workshopTicketMapping["GuestAndSponsorWithVisit"], workshopTicketMapping["GuestAndSponsorWithoutVisit"])}><i className={`bi bi${ticketsToAdd.includes(settings.guestAndSponsorTicketTypeWithVisitId) ? "-check" : ""}-circle`}></i></div>
                            <div className="col-8">
                                {resources?.Workshop3Sub2}
                            </div>
                        </div>
                    </div>
                </div>}

                <div className='workshops mb-5'>

                    {/* Swiss Tunnel Day ticket type */}
                    <div className={`category row ${(ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.congressTicketTypeId)?.availability?.availableTickets <= 0) ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Congress")}><i className={`bi bi${ticketsToAdd.includes(settings.congressTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10" onClick={() => onCheckboxChange("Congress")}>{resources?.Workshop4}</div>
                    </div>

                    {/* Gedruckter Tagungsband */}
                    <div className={`category row ${(ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.tagungsbandTicketTypeId)?.availability?.availableTickets <= 0 && tagungsBands <= 0) ? "category-hide" : ""}`}>
                        <div
                            className="col-2 fs-5 text-secondary"
                            onClick={() => {
                                if (addTagungsband) {
                                    setAddTagungsband(false);
                                    setTagungsBands();
                                } else {
                                    setAddTagungsband(true);
                                }
                            }}>
                            <i className={`bi bi${addTagungsband ? "-check" : ""}-square`}></i>
                        </div>

                        <div className="col-10">
                            <span onClick={() => {
                                if (addTagungsband) {
                                    setAddTagungsband(false);
                                    setTagungsBands();
                                } else {
                                    setAddTagungsband(true);
                                }
                            }}>
                                {resources?.Workshop7}
                            </span>

                            {addTagungsband ? <div className="row mt-2">
                                <div className="col-12 row">

                                    <div className='col-md-2 col-12'>
                                        <input className="form-control text-center" type="number"
                                            placeholder="0"
                                            value={tagungsBands}
                                            min={0}
                                            step={1}
                                            onChange={(e) => setTagungsBands(e.target.value)}
                                            disabled={!addTagungsband} />
                                    </div>
                                    <div className='col-md-10 col-12'>
                                        {resources?.Workshop7Sub1}
                                    </div>
                                </div>
                            </div> : <></>}
                        </div>
                    </div>

                    {/* AGB */}
                    <div className='category row'>
                        <div className="col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, accept: !agb.accept })}><i className={`bi bi-${agb.accept ? "check-" : ""}circle`}></i></div>
                        <div className="col-10" onClick={() => setAgb({ ...agb, accept: !agb.accept })}>{resources?.AGB1} <span className="agb-url" onClick={openAGB}>{resources?.AGB01}</span> {resources?.AGB11}
                        </div>

                        <div className="col-12">
                            <div className='row mt-2'>
                                <div className="col-2"></div>
                                <div className="col">
                                    <p>{resources?.AGB2}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-2 col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, publication: !agb.publication })}><i className={`bi bi-${agb.publication ? "check-" : ""}circle`}></i></div>
                                <div className="col-8" onClick={() => setAgb({ ...agb, publication: !agb.publication })}>{resources?.Yes}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="offset-2 col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, publication: !agb.publication })}><i className={`bi bi-${agb.publication ? "" : "check-"}circle`}></i></div>
                                <div className="col-8" onClick={() => setAgb({ ...agb, publication: !agb.publication })}>{resources?.No}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Promotion codes */}
                <div className='mb-4'>

                    {/* Promotion guest (Unlock) */}
                    <div className="row">
                        <span>{resources?.PromotionCodeDescGuest}</span>
                        <div className="col-md-9 col-7 mb-2">
                            <input className='form-control' placeholder={resources?.PromotionCode} value={promotioncodeUnlock} onChange={(e) => setPromotioncodeUnlock(e.target.value)} />
                        </div>
                        <div className='col-md-3 col-5 text-end'>
                            <button className='btn button' onClick={addPromotioncodeUnlock}>{resources?.Redeem}</button>
                        </div>
                        {promotionErrorUnlock != "" && <div className="col-12"><p className='text-danger fs-6 mb-0'>{promotionErrorUnlock}</p></div>}
                        {(isPromotionAddedUnlock && promotionErrorUnlock == "") &&
                            <div className="col-12"><p className='text-success fs-6 mb-0'>{resources?.SuccessPromotionCode}</p></div>}
                    </div>

                    {/* Promotion swiss tunnel day (Discount) */}
                    <div className="row mt-4">
                        <span>{resources?.PromotionCodeDescDay}</span>
                        <div className="col-md-9 col-7 mb-2">
                            <input className='form-control' placeholder={resources?.PromotionCode} value={promotioncodeDiscount} onChange={(e) => setPromotioncodeDiscount(e.target.value)} />
                        </div>
                        <div className='col-md-3 col-5 text-end'>
                            <button className='btn button' onClick={addPromotioncodeDiscount}>{resources?.Redeem}</button>
                        </div>
                        {promotionErrorDiscount != "" && <div className="col-12"><p className='text-danger fs-6 mb-0'>{promotionErrorDiscount}</p></div>}
                        {(isReductionPromotionAdded && promotionErrorDiscount == "") &&
                            <div className="col-12"><p className='text-success fs-6 mb-0'>{resources?.SuccessPromotionCode}</p></div>}
                    </div>

                    <p className="mt-3">{resources?.PromotionCodeDesc} <Link to={languageId == 2 ? "/files/STS_Instructions pour l'utilisation des codes promo_FR.pdf" : languageId == 3 ? "/files/STS_How to redeem promocodes_EN.pdf" : "/files/STS_Anleitung_Promocodes.pdf"} target="_blank">{resources?.PromotionCodeDesc1}</Link>.</p>

                </div>

                {/* Navigation buttons */}
                <div className='row mt-5'>
                    {submissionError != "" && <div className="col-12">
                        <p className='text text-danger mt-0'>{submissionError}</p>
                    </div>}
                    <div className="col-6">
                        <button className='btn button text-start' onClick={() => navigate(-1)}>{resources?.Back}</button>
                    </div>
                    <div className="col-6 text-end">
                        <button className='btn button' disabled={disabled} onClick={onSubmit}>{resources?.Next}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Participation