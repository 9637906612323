import React from 'react'

import flyer from "../../images/logo.png";
import bannerWithText from "../../images/banner_with_Text.jpg";
import bannerWithoutText from "../../images/banner_without_Text.jpg";
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props) => {

    let bannerImg = bannerWithoutText;

    if (props.isWithText) {
        bannerImg = bannerWithText;
    }

    return (
        <div>
            <div className="mt-4 mb-4">
                <img className='text-center flyer' src={flyer} alt="sts logo"></img>
            </div>
            <div className='row mb-4'>
                <div className='col-12 fill'>
                    <img className='img-fluid' src={bannerImg} alt="sts banner"></img>
                </div>
            </div>
        </div>
    )
}

export default Header