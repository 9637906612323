import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist'
import { api } from 'ticketino-api-client'
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Home = () => {
    const [selectedCategory, setSelectedCategory] = useState("");

    const [posId, setPosId] = useState();
    const [eventId, setEventId] = useState();
    const [orderId, setOrderId] = useState();
    const [ticketTypeId, setTicketTypeId] = useState();

    const [error, setError] = useState("")

    let navigate = useNavigate();

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    const categories = [
        { id: 1, name: "FGU Kollektivmitglied", content: resources?.Kollektivmitglied },
        { id: 2, name: "FGU Einzelmitglied", content: resources?.Einzelmitglied },
        { id: 3, name: "FGU Einzelmitglied STSym", content: resources?.Stsym },
        { id: 4, name: "Nichtmitglied", content: resources?.Nichtmitglied },
        { id: 5, name: "Student", content: resources?.Student }
    ]

    let { language } = useParams();

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            language = "de";
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);
            setTicketTypeId(res.data.stsTicketTypeId);
        });
    };

    const startOrder = async (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order`, order);
            return response.data;
        } catch (error) {
            setError(error.response.data)
            console.error(error.response.data);
        }
    };

    const onSubmit = async () => {
        const order = await startOrder(posId);

        if (order) {
            sessionStorage.setItem("selectedCategory", selectedCategory);
            sessionStorage.setItem("orderId", order.id);
            sessionStorage.setItem("eventId", eventId);
            sessionStorage.setItem("addedPromotion", "");
            sessionStorage.setItem("agbPublication", false);
            navigate(`/${language}/address`);
        }
    }

    let disabled = /^.{1,1000}$/.test(selectedCategory);

    return (
        <div className='container page-container'>
            <Header isWithText={true} />
            <div className='mt-3'>
                <div className='text-center'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources?.HomeTitle}</span>
                    </p>
                    <p>{resources?.HomeSubTitle}</p>
                    <p>{resources?.HomeDescription}</p>
                    {/*<p className='text-primary mb-5'>{parse(resources?.ExkursionWarning ?? "")}</p>*/}
                    <p className='text-start mb-3'>{resources?.IAm}</p>
                </div>
                <div className='categories mb-5'>
                    {categories.map((category, index) => (
                        <div className='category row' onClick={() => setSelectedCategory(category.name)} key={index}>
                            <div className="col-2 fs-5 text-secondary"><i className={`bi bi${(selectedCategory == category.name) ? "-check" : ""}-square`}></i></div>
                            <div className="col-10">
                                {category.content}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='mb-5'>
                    <p>{resources?.HomeNote1}</p>
                    <p>{resources?.HomeNote2}</p>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <a className='btn button text-start' href="https://www.swisstunnel.ch/">{resources?.Back}</a>
                    </div>
                    <div className="col-6 text-end">
                        <button className='btn button' disabled={!disabled} onClick={onSubmit}>{resources?.Next}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home