import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist'
import { api, getAddressOfOrder, getCountries } from 'ticketino-api-client'

import Header from './components/Header'
import Footer from './components/Footer'

const defaultAddress = {
    salutation: "",
    firstName: "",
    name: "",
    company: "",
    address2: "",
    street: "",
    postCode: "",
    city: "",
    countryId: 0,
    email: ""
}

const Address = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [selectedCategory] = useState(sessionStorage.getItem("selectedCategory"));

    const [address, setAddress] = useState({ ...defaultAddress });
    const [countries, setCountries] = useState([]);

    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            language = "de";
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
        loadCountries();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadAddress(orderId);
        }
    }, [orderId]);

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadAddress = async (orderId) => {
        try {
            const updatedAddress = await getAddressOfOrder(orderId);
            setAddress({ ...updatedAddress });
        } catch (error) {
            console.error(error);
        }
    }

    const loadCountries = async () => {
        try {
            let updatedCountries = await getCountries(language);

            const indexCh = updatedCountries.findIndex(c => c.id == 176);
            const indexDe = updatedCountries.findIndex(c => c.id == 229);
            const indexAu = updatedCountries.findIndex(c => c.id == 228);

            const ch = updatedCountries[indexCh];
            const de = updatedCountries[indexDe];
            const au = updatedCountries[indexAu];

            updatedCountries.splice(indexCh, 1);
            updatedCountries.splice(indexDe, 1);
            updatedCountries.splice(indexAu, 1);

            updatedCountries.unshift(au);
            updatedCountries.unshift(de);
            updatedCountries.unshift(ch);

            setCountries(updatedCountries);

        } catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async () => {
        let addressBody = { ...address };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => navigate(`/${language}/participation`))
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onInputChange = (e) => {
        setAddress({ ...address, [e.target.name]: e.target.value });
    }

    // validating the input fields
    let validation =
        address.salutation &&
        /^.{1,70}$/.test(address.salutation) &&
        /^.{1,70}$/.test(address.firstName) &&
        /^.{1,70}$/.test(address.name) &&
        /^.{1,70}$/.test(address.street) &&
        /^.{1,70}$/.test(address.postCode) &&
        /^.{1,70}$/.test(address.city) &&
        /^.{1,70}$/.test(address.countryId) &&
        /^\S+@\S+\.\S+$/.test(address.email) &&
        (address.countryId > 0) ;

    if (selectedCategory == "FGU Kollektivmitglied") {
        validation = validation && (address.company && /^.{1,70}$/.test(address.company))
    }

    return (
        <div className='container page-container'>
            <Header />
            <div className='mt-3'>
                <div className='text-center'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources?.AddressTitle}</span>
                    </p>
                    <p className='mb-4'>{resources?.AddressDescription}</p>
                </div>
                <div className='mb-5'>
                    <div className="mb-3">
                        <select className="form-select input-field" name='salutation' value={address.salutation} onChange={onInputChange}>
                            <option value="">{resources?.Salutation}</option>
                            <option value={resources?.Mr}>{resources?.Mr}</option>
                            <option value={resources?.Ms}>{resources?.Ms}</option>
                            <option value={resources?.Diverse}>{resources?.Diverse}</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.FirstName} name='firstName' value={address.firstName} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.Name} name='name' value={address.name} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.Company + (selectedCategory == "FGU Kollektivmitglied" ? "*" : "")} name='company' value={address.company} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.Other} name='address2' value={address.address2} onChange={onInputChange} disabled={address.company.length <= 0 } />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.Street} name='street' value={address.street} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.PostCode} name='postCode' value={address.postCode} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.City} name='city' value={address.city} onChange={onInputChange} />
                    </div>
                    <div className="mb-3">
                        <select className="form-select input-field" name='countryId' value={address.countryId} onChange={onInputChange}>
                            <option value="">{resources?.Country} *</option>
                            {countries.map((country, index) => (
                                <option value={country.id} key={index}>{country.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <input className="form-control input-field" type="text" placeholder={resources?.Email} name='email' value={address.email} onChange={onInputChange} />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <button className='btn button text-start' onClick={() => navigate(-1)}>{resources?.Back}</button>
                    </div>
                    <div className="col-6 text-end">
                        <button className='btn button' disabled={!validation} onClick={addAddressToBasket}>{resources?.Next}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Address