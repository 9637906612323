import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';

import logo from "../../images/footer-logo.png";

const Footer = () => {

    let navigate = useNavigate();

    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div className='footer'>
            <div className="row">
                <div className="col-md-5 col-12 row footer-element">
                    <div className="col-3">
                        <img className='img-fluid' src={logo}></img>
                    </div>
                    <div className="col-9">
                        <ul className='footer-link'>
                            <li>STS Swiss Tunelling Society</li>
                            <li>Via del Tiglio 2</li>
                            <li>C. P. 934</li>
                            <li>CH-6512 Bellinzona-Giubiasco</li>
                            <li><a className='email-link' href="mailto:sia-fgu@swisstunnel.ch">sia-fgu@swisstunnel.ch</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-7 col-12 row">
                    <div className="col-md-6 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>{resources?.FooterDiscover}</li>
                            <li><a href={resources?.FooterDiscover1Url}>{resources?.FooterDiscover1}</a></li>
                            <li><a href={resources?.FooterDiscover2Url}>{resources?.FooterDiscover2}</a></li>
                            <li><a href={resources?.FooterDiscover3Url}>{resources?.FooterDiscover3}</a></li>
                            <li><a href={resources?.FooterDiscover4Url}>{resources?.FooterDiscover4}</a></li>
                            <li><a href={resources?.FooterDiscover5Url}>{resources?.FooterDiscover5}</a></li>
                            <li><a href={resources?.FooterDiscover6Url}>{resources?.FooterDiscover6}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>{resources?.FooterMedia}</li>
                            <li><a href={resources?.FooterMedia1Url}>{resources?.FooterMedia1}</a></li>
                            <li><a href={resources?.FooterMedia2Url}>{resources?.FooterMedia2}</a></li>
                            <li><a href={resources?.FooterMedia3Url}>{resources?.FooterMedia3}</a></li>
                            <li><a href={resources?.FooterMedia4Url}>{resources?.FooterMedia4}</a></li>
                            <li><a href={resources?.FooterMedia5Url}>{resources?.FooterMedia5}</a></li>
                            <li><a href={resources?.FooterMedia6Url}>{resources?.FooterMedia6}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>{resources?.FooterLanguage}</li>
                            <li className='footer-language' onClick={() => navigate(`/de/home`)}>Deutsch</li>
                            <li className='footer-language' onClick={() => navigate(`/fr/home`)}>Français</li>
                            <li className='footer-language' onClick={() => navigate(`/en/home`)}>English</li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 footer-end'>
                    <li className='me-3'>{resources?.FooterContact}</li>
                    <li className='me-3'><a href={resources?.FooterContact1Url}>{resources?.FooterContact1}</a></li>
                    <li className='me-3'><a href={resources?.FooterContact2Url}>{resources?.FooterContact2}</a></li>
                    <li className='me-3'><a href={resources?.FooterContact3Url}>{resources?.FooterContact3}</a></li>
                    <li className='me-3'><a href={resources?.FooterContact4Url}>{resources?.FooterContact4}</a></li>
                </div>
            </div>
        </div>
    )
}

export default Footer